<template>
  <div class="about">
    <v-container>
      <v-row justify="center">
        <v-col>
          <h1>FAQ</h1>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</v-col>
      </v-row>
      <v-row justify="center">
        <v-col>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</v-col>
      </v-row>
      <v-row justify="center" class="text-left">
        <v-col><v-card elevation="3"><v-card-text>1</v-card-text></v-card></v-col>
        <v-col><v-card elevation="3"><v-card-text>2</v-card-text></v-card></v-col>
        <v-col><v-card elevation="3"><v-card-text>3</v-card-text></v-card></v-col>
      </v-row>
      <v-row justify="center" class="text-left">
        <v-col><v-card elevation="3"><v-card-text>1</v-card-text></v-card></v-col>
        <v-col><v-card elevation="3"><v-card-text>2</v-card-text></v-card></v-col>
      </v-row>
      <v-row justify="center">
        <v-col>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</v-col>
      </v-row>
      <v-row justify="center">
        <v-col>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</v-col>
      </v-row>
      <v-row justify="center" class="text-left">
        <v-col><v-card elevation="3"><v-card-text>1</v-card-text></v-card></v-col>
        <v-col><v-card elevation="3"><v-card-text>2</v-card-text></v-card></v-col>
        <v-col><v-card elevation="3"><v-card-text>3</v-card-text></v-card></v-col>
      </v-row>
      <v-row justify="center" class="text-left">
        <v-col><v-card elevation="3"><v-card-text>1</v-card-text></v-card></v-col>
        <v-col><v-card elevation="3"><v-card-text>2</v-card-text></v-card></v-col>
        <v-col><v-card elevation="3"><v-card-text>3</v-card-text></v-card></v-col>
      </v-row>
      <v-row justify="center">
        <v-col>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</v-col>
      </v-row>
      <v-row justify="center" class="text-left">
        <v-col><v-card elevation="3"><v-card-text>1</v-card-text></v-card></v-col>
        <v-col><v-card elevation="3"><v-card-text>2</v-card-text></v-card></v-col>
        <v-col><v-card elevation="3"><v-card-text>3</v-card-text></v-card></v-col>
      </v-row>
    </v-container>
    <v-container justify="center">
      <quick-navigation />
    </v-container>
  </div>
</template>

<script>
import QuickNavigation from "../components/QuickNavigation";

export default {
  name: "About",

  components: {
    QuickNavigation,
  },
};
</script>